<template>
  <div class="px-5">
    <basic-form :title="title" @save="save" :isLoading.sync="loading">
      <template slot="fields">
        <div class="flex flex-col md:flex-row justify-center">
          <div class="w-full md:w-1/2 mr-3">
            <t-input-group
              v-if="!type"
              :feedback="
                showValidation && errors.first('type')
                  ? errors.first('type')
                  : ''
              "
              :variant="
                showValidation && errors.first('type') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Tipo de título'"
            >
              <t-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.type"
                name="type"
                id="type"
                placeholder="Seleccione una opción"
                :disabled="loading"
                :options="[{ index: 1, name: 'Academia' },{ index: 2, name: 'Entrenador' },{ index: 3, name: 'Jugador' }]"
                textAttribute="name"
                valueAttribute="index"
                :variant="
                  showValidation && errors.first('type') ? 'danger' : ''
                "
              ></t-select>
            </t-input-group>

            <t-input-group
              v-if="form.type > 0 || type"
              :feedback="
                showValidation && errors.first('titles')
                  ? errors.first('titles')
                  : ''
              "
              :variant="
                showValidation && errors.first('titles') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Título'"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.id_title"
                name="titles"
                id="titles"
                placeholder="Seleccione una opción"
                :disabled="loading"
                noResultsText="No se encontraron resultados"
                searchBoxPlaceholder="Busca..."
                :options="titles"
                textAttribute="name"
                valueAttribute="index"
                :multiple="false"
                :closeOnSelect="true"
                :clearable="false"
                :variant="
                  showValidation && errors.first('titles') ? 'danger' : ''
                "
              ></t-rich-select>
            </t-input-group>

            <t-input-group
              v-if="form.type > 0 || type"
              :feedback="
                showValidation && errors.first('year') ? errors.first('year') : ''
              "
              :variant="showValidation && errors.first('year') ? 'danger' : ''"
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Año de obtención del título'"
            >
              <t-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.year"
                name="year"
                id="year"
                placeholder="Seleccione una opción"
                :disabled="loading"
                :options="[{ index: 2021, name: '2021' },{ index: 2022, name: '2022' },{ index: 2023, name: '2023' },{ index: 2024, name: '2024' }]"
                textAttribute="name"
                valueAttribute="index"
                :variant="
                  showValidation && errors.first('year') ? 'danger' : ''
                "
              ></t-select>
            </t-input-group>
          </div>

          <div class="w-full md:w-1/2 mx-1">
            <t-input-group
              v-if="form.type == 2 || form.type == 3 || type == 1"
              :feedback="
                showValidation && errors.first('academy')
                  ? errors.first('academy')
                  : ''
              "
              :variant="
                showValidation && errors.first('academy') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Academia (ayudante de búsqueda)'"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.id_academy"
                name="academy"
                id="academy"
                placeholder="Seleccione una opción"
                :disabled="loading"
                noResultsText="No se encontraron resultados"
                searchBoxPlaceholder="Busca..."
                :options="academies"
                textAttribute="name"
                valueAttribute="index"
                :multiple="false"
                :closeOnSelect="true"
                :clearable="true"
                :variant="
                  showValidation && errors.first('academies') ? 'danger' : ''
                "
              ></t-rich-select>
            </t-input-group>

            <t-input-group
              v-if="form.type == 1 || type == 1"
              :feedback="
                showValidation && errors.first('academy')
                  ? errors.first('academy')
                  : ''
              "
              :variant="
                showValidation && errors.first('academy') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Academia'"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.id_academy"
                name="academy"
                id="academy"
                placeholder="Seleccione una opción"
                :disabled="loading"
                noResultsText="No se encontraron resultados"
                searchBoxPlaceholder="Busca..."
                :options="academies"
                textAttribute="name"
                valueAttribute="index"
                :multiple="false"
                :closeOnSelect="true"
                :clearable="false"
                :variant="
                  showValidation && errors.first('academies') ? 'danger' : ''
                "
              ></t-rich-select>
            </t-input-group>

            <t-input-group
              v-if="form.type == 2 || type == 2"
              :feedback="
                showValidation && errors.first('coaches')
                  ? errors.first('coaches')
                  : ''
              "
              :variant="
                showValidation && errors.first('coaches') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Entrenador'"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.id_coach"
                name="coach"
                id="coach"
                placeholder="Seleccione una opción"
                :disabled="loading"
                noResultsText="No se encontraron resultados"
                searchBoxPlaceholder="Busca..."
                :options="coaches"
                textAttribute="name"
                valueAttribute="index"
                :multiple="false"
                :closeOnSelect="true"
                :clearable="false"
                :variant="
                  showValidation && errors.first('coaches') ? 'danger' : ''
                "
              ></t-rich-select>
            </t-input-group>

            <t-input-group
              v-if="form.type == 3 || type == 3"
              :feedback="
                showValidation && errors.first('players')
                  ? errors.first('players')
                  : ''
              "
              :variant="
                showValidation && errors.first('players') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Jugador'"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.id_player"
                name="player"
                id="player"
                placeholder="Seleccione una opción"
                :disabled="loading"
                noResultsText="No se encontraron resultados"
                searchBoxPlaceholder="Busca..."
                :options="players"
                textAttribute="name"
                valueAttribute="index"
                :multiple="false"
                :closeOnSelect="true"
                :clearable="false"
                :variant="
                  showValidation && errors.first('players') ? 'danger' : ''
                "
              ></t-rich-select>
            </t-input-group>

            <t-input-group
              v-if="form.type > 0 || type"
              :feedback="
                showValidation && errors.first('observation')
                  ? errors.first('observation')
                  : ''
              "
              :variant="
                showValidation && errors.first('observation') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Observación (opcional)'"
            >
              <t-textarea
                v-validate="'max:200'"
                data-vv-validate-on="input"
                v-model="form.observation"
                name="observation"
                placeholder="Ingrese algún comentario respecto al título obtenido"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('observation') ? 'danger' : ''
                "
                rows="3"
              />
            </t-input-group>
          </div>
        </div>

        <div class="w-full md:w-2/3"></div>
        <div
          class="w-full md:w-1/3 justify-center"
          v-if="deletable"
        >
          <t-button type="button" variant="error" @click="deleteElement"
            >Eliminar título</t-button
          >
        </div>
      </template>
    </basic-form>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
export default {
  components: {},
  props: {
    title: {
      type: String
    },
    deletable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      form: {
        type: null,
        id_academy: null
      },
      showValidation: false,
      visible: false
    }
  },
  computed: {
    type() {
      return this.$route.query.type ? this.$route.query.type : null
    },
    titles() {
      if (this.form.type) {
        return this.$store.getters["global/getItems"](
          "title",
          "unpaginated_elements"
        ).filter(el => el.type == this.form.type).map((el) => {
          return {
            index: el.id,
            name: el.name,
          }
        })
      }
      else {
        return this.$store.getters["global/getItems"](
          "title",
          "unpaginated_elements"
        ).map((el) => {
          return {
            index: el.id,
            name: el.name,
          }
        })
      }
    },
    academies() {
      return this.$store.getters["global/getItems"](
        "academy",
        "unpaginated_elements"
      ).map((el) => {
        return {
          index: el.id,
          name: el.name,
        }
      })
    },
    coaches() {
      return this.$store.getters["global/getItems"](
        "coach",
        "unpaginated_elements"
      ).filter(
        el => this.form.id_academy > 0 ? el.id_academy == this.form.id_academy : true
      ).map((el) => {
        return {
          index: el.id,
          name: el.first_name + " " + el.last_name,
        }
      })
    },
    players() {
      return this.$store.getters["global/getItems"](
        "player",
        "unpaginated_elements"
      ).filter(
        el => this.form.id_academy > 0 ? el.id_academy == this.form.id_academy : true
      ).map((el) => {
        return {
          index: el.id,
          name: el.jersey_number + '. ' + el.first_name + " " + el.last_name,
        }
      })
    },
  },
  methods: {
    getCoaches() {
      let form = {
        route: `coach/all`,
        module: 'coach',
        isMaster: false,
        noPaginate: true,
        params: {
          active: 1,
          order_key: 'first_name',
          order_value: 'asc',
          verified: 1,
        }
      }

      this.$store.dispatch('global/getItems', form)
    },
    getAcademies() {
      let form = {
        route: `academy/all`,
        module: 'academy',
        isMaster: false,
        noPaginate: true,
        params: {
          active: 1,
          order_key: 'name',
          order_value: 'asc',
          verified: 1,
        }
      }

      this.$store.dispatch('global/getItems', form)
    },
    getPlayers() {
      let form = {
        route: `player/all`,
        module: 'player',
        isMaster: false,
        noPaginate: true,
        params: {
          active: 1,
          //order_key: 'first_name',
          order_key: 'jersey_number',
          order_value: 'asc',
          verified: 1,
        }
      }

      this.$store.dispatch('global/getItems', form)
    },
    getItems(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc",
            type: this.type ? this.type : null,
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    toggleVisibility(visible) {
      this.$emit("toggleVisibility", visible)
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          if (!this.form.observation)   this.form.observation = ' '
          this.$emit("save")
        }
      })
    },

    deleteElement() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente al título.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            let route = ''

            if (this.type == 1) route = '/academy_title'
            if (this.type == 2) route = '/coach_title'
            if (this.type == 3) route = '/player_title'

            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.form.id,
                  status: 2
                },
                route: route,
                module: "otherTitle"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200) this.$router.push("/titulos")
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    }
  },
  created() {
    this.getItems("/title/all", "title", true)

    if (this.type == 1) this.getAcademies()
    if (this.type == 2) this.getCoaches()
    if (this.type == 3) this.getPlayers()

    if (!this.type) {
      this.getAcademies()
      this.getCoaches()
      this.getPlayers()
    }
  }
}
</script>
