var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('basic-form',{attrs:{"title":_vm.title,"isLoading":_vm.loading},on:{"save":_vm.save,"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event}}},[_c('template',{slot:"fields"},[_c('div',{staticClass:"flex flex-col md:flex-row justify-center"},[_c('div',{staticClass:"w-full md:w-1/2 mr-3"},[(!_vm.type)?_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('type')
                ? _vm.errors.first('type')
                : '',"variant":_vm.showValidation && _vm.errors.first('type') ? 'danger' : '',"label":'Tipo de título'}},[_c('t-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"type","id":"type","placeholder":"Seleccione una opción","disabled":_vm.loading,"options":[{ index: 1, name: 'Academia' },{ index: 2, name: 'Entrenador' },{ index: 3, name: 'Jugador' }],"textAttribute":"name","valueAttribute":"index","variant":_vm.showValidation && _vm.errors.first('type') ? 'danger' : ''},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1):_vm._e(),(_vm.form.type > 0 || _vm.type)?_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('titles')
                ? _vm.errors.first('titles')
                : '',"variant":_vm.showValidation && _vm.errors.first('titles') ? 'danger' : '',"label":'Título'}},[_c('t-rich-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"titles","id":"titles","placeholder":"Seleccione una opción","disabled":_vm.loading,"noResultsText":"No se encontraron resultados","searchBoxPlaceholder":"Busca...","options":_vm.titles,"textAttribute":"name","valueAttribute":"index","multiple":false,"closeOnSelect":true,"clearable":false,"variant":_vm.showValidation && _vm.errors.first('titles') ? 'danger' : ''},model:{value:(_vm.form.id_title),callback:function ($$v) {_vm.$set(_vm.form, "id_title", $$v)},expression:"form.id_title"}})],1):_vm._e(),(_vm.form.type > 0 || _vm.type)?_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('year') ? _vm.errors.first('year') : '',"variant":_vm.showValidation && _vm.errors.first('year') ? 'danger' : '',"label":'Año de obtención del título'}},[_c('t-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"year","id":"year","placeholder":"Seleccione una opción","disabled":_vm.loading,"options":[{ index: 2021, name: '2021' },{ index: 2022, name: '2022' },{ index: 2023, name: '2023' },{ index: 2024, name: '2024' }],"textAttribute":"name","valueAttribute":"index","variant":_vm.showValidation && _vm.errors.first('year') ? 'danger' : ''},model:{value:(_vm.form.year),callback:function ($$v) {_vm.$set(_vm.form, "year", $$v)},expression:"form.year"}})],1):_vm._e()],1),_c('div',{staticClass:"w-full md:w-1/2 mx-1"},[(_vm.form.type == 2 || _vm.form.type == 3 || _vm.type == 1)?_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('academy')
                ? _vm.errors.first('academy')
                : '',"variant":_vm.showValidation && _vm.errors.first('academy') ? 'danger' : '',"label":'Academia (ayudante de búsqueda)'}},[_c('t-rich-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"academy","id":"academy","placeholder":"Seleccione una opción","disabled":_vm.loading,"noResultsText":"No se encontraron resultados","searchBoxPlaceholder":"Busca...","options":_vm.academies,"textAttribute":"name","valueAttribute":"index","multiple":false,"closeOnSelect":true,"clearable":true,"variant":_vm.showValidation && _vm.errors.first('academies') ? 'danger' : ''},model:{value:(_vm.form.id_academy),callback:function ($$v) {_vm.$set(_vm.form, "id_academy", $$v)},expression:"form.id_academy"}})],1):_vm._e(),(_vm.form.type == 1 || _vm.type == 1)?_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('academy')
                ? _vm.errors.first('academy')
                : '',"variant":_vm.showValidation && _vm.errors.first('academy') ? 'danger' : '',"label":'Academia'}},[_c('t-rich-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"academy","id":"academy","placeholder":"Seleccione una opción","disabled":_vm.loading,"noResultsText":"No se encontraron resultados","searchBoxPlaceholder":"Busca...","options":_vm.academies,"textAttribute":"name","valueAttribute":"index","multiple":false,"closeOnSelect":true,"clearable":false,"variant":_vm.showValidation && _vm.errors.first('academies') ? 'danger' : ''},model:{value:(_vm.form.id_academy),callback:function ($$v) {_vm.$set(_vm.form, "id_academy", $$v)},expression:"form.id_academy"}})],1):_vm._e(),(_vm.form.type == 2 || _vm.type == 2)?_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('coaches')
                ? _vm.errors.first('coaches')
                : '',"variant":_vm.showValidation && _vm.errors.first('coaches') ? 'danger' : '',"label":'Entrenador'}},[_c('t-rich-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"coach","id":"coach","placeholder":"Seleccione una opción","disabled":_vm.loading,"noResultsText":"No se encontraron resultados","searchBoxPlaceholder":"Busca...","options":_vm.coaches,"textAttribute":"name","valueAttribute":"index","multiple":false,"closeOnSelect":true,"clearable":false,"variant":_vm.showValidation && _vm.errors.first('coaches') ? 'danger' : ''},model:{value:(_vm.form.id_coach),callback:function ($$v) {_vm.$set(_vm.form, "id_coach", $$v)},expression:"form.id_coach"}})],1):_vm._e(),(_vm.form.type == 3 || _vm.type == 3)?_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('players')
                ? _vm.errors.first('players')
                : '',"variant":_vm.showValidation && _vm.errors.first('players') ? 'danger' : '',"label":'Jugador'}},[_c('t-rich-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"player","id":"player","placeholder":"Seleccione una opción","disabled":_vm.loading,"noResultsText":"No se encontraron resultados","searchBoxPlaceholder":"Busca...","options":_vm.players,"textAttribute":"name","valueAttribute":"index","multiple":false,"closeOnSelect":true,"clearable":false,"variant":_vm.showValidation && _vm.errors.first('players') ? 'danger' : ''},model:{value:(_vm.form.id_player),callback:function ($$v) {_vm.$set(_vm.form, "id_player", $$v)},expression:"form.id_player"}})],1):_vm._e(),(_vm.form.type > 0 || _vm.type)?_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('observation')
                ? _vm.errors.first('observation')
                : '',"variant":_vm.showValidation && _vm.errors.first('observation') ? 'danger' : '',"label":'Observación (opcional)'}},[_c('t-textarea',{directives:[{name:"validate",rawName:"v-validate",value:('max:200'),expression:"'max:200'"}],attrs:{"data-vv-validate-on":"input","name":"observation","placeholder":"Ingrese algún comentario respecto al título obtenido","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('observation') ? 'danger' : '',"rows":"3"},model:{value:(_vm.form.observation),callback:function ($$v) {_vm.$set(_vm.form, "observation", $$v)},expression:"form.observation"}})],1):_vm._e()],1)]),_c('div',{staticClass:"w-full md:w-2/3"}),(_vm.deletable)?_c('div',{staticClass:"w-full md:w-1/3 justify-center"},[_c('t-button',{attrs:{"type":"button","variant":"error"},on:{"click":_vm.deleteElement}},[_vm._v("Eliminar título")])],1):_vm._e()])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }