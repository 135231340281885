<template>
  <div class="px-5">
    <title-form
      title="Editar título"
      ref="titleForm"
      :deletable="true"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import TitleForm from "./Form.vue"
export default {
  components: {
    TitleForm
  },
  data() {
    return {
      loading: true,
      form: {},
      type: this.$route.query.type
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    getItem() {
      let route = ""

      if (this.type == 1) route = "/academy_title"
      if (this.type == 2) route = "/coach_title"
      if (this.type == 3) route = "/player_title"

      this.$store
        .dispatch("global/getItem", {
          route: route,
          module: "otherTitle",
          id: this.$route.params.id
        })
        .then((response) => {
          this.loading = false
          this.form = response.data
          this.$refs.titleForm.form = response.data
          this.$refs.titleForm.form.observation =
            this.form.observation == " " ? null : this.form.observation
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    toggleVisibility() {
      let route = ""

      if (this.$route.query.type == 1) route = "/academy_title"
      if (this.$route.query.type == 2) route = "/coach_title"
      if (this.$route.query.type == 3) route = "/player_title"

      this.$store
        .dispatch("global/changeStatus", {
          payload: {
            id: this.$refs.titleForm.form.id,
            status: !this.$refs.titleForm.visible ? 1 : 0
          },
          route: route,
          module: "player"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master-status", response.code, this.$snotify)
          })
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    save() {
      let form = this.$refs.titleForm.form
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          this.loading = true
          let route = ""
          let payload = {}

          if (this.$route.query.type == 1) {
            route = "/academy_title"

            payload = {
              id: form.id,
              id_academy: form.id_academy,
              id_title: form.id_title,
              year: form.year,
              observation: form.observation
            }
          }
          if (this.$route.query.type == 2) {
            route = "/coach_title"

            payload = {
              id: form.id,
              id_coach: form.id_coach,
              id_title: form.id_title,
              year: form.year,
              observation: form.observation
            }
          }
          if (this.$route.query.type == 3) {
            route = "/player_title"

            payload = {
              id: form.id,
              id_player: form.id_player,
              id_title: form.id_title,
              year: form.year,
              observation: form.observation
            }
          }

          this.$store
            .dispatch("global/update", {
              payload: payload,
              route: route + "/update",
              module: "otherTitle"
            })
            .then((response) => {
              notify().then(({ notification }) => {
                notification("master-update", response.code, this.$snotify)
              })
              if ([200, 201].includes(response.code))
                this.$router.push("/titulos")
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              notify().then(({ notification }) => {
                notification("error", error.code, this.$snotify)
              })
            })
        }
      })
    },

    deleteTitle() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente al título.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            let route = ""

            if (this.$route.query.type == 1) route = "/academy_title"
            if (this.$route.query.type == 2) route = "/coach_title"
            if (this.$route.query.type == 3) route = "/player_title"

            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.$refs.titleForm.form.id,
                  status: 2
                },
                route: route,
                module: "otherTitle"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200) this.$router.push("/titulos")
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    }
  },
  created() {
    this.getItem()
  }
}
</script>
